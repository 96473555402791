var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"py-0"},[_c('v-btn',{staticClass:"ml-n3 mr-2 my-2 py-0",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('h2',{staticClass:"font-weight-regular my-2 py-0"},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'WO' : 'Process work order')+" "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.order.code)+" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? '' : `| ${_vm.order.project.reference} ${_vm.order.project.name}`))])]),(_vm.order.rejectionHistory)?_c('v-btn',{staticClass:"ml-2 py-0",attrs:{"small":"","icon":"","color":"red"},on:{"click":_vm.openRejected}},[_c('v-icon',[_vm._v(" mdi-book-cancel-outline ")])],1):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"d-flex py-0"},[_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.downloadPDFs}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.downloadXLS}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.print}},[_c('v-icon',[_vm._v("mdi-printer-pos")])],1)],1)],1),_c('v-divider',{staticClass:"ml-4 mr-5 py-0"}),_c('v-card-text',{staticClass:"px-4 my-0 py-0"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.sections),function(section){return _c('v-tab',{key:section,staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(section)+" ")])}),1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',{style:(`height: ${
                            _vm.$vuetify.breakpoint.xs
                                ? _vm.$vuetify.breakpoint.height - 110
                                : _vm.$vuetify.breakpoint.sm
                                ? _vm.$vuetify.breakpoint.height - 250
                                : _vm.$vuetify.breakpoint.height - 124
                        }px`),attrs:{"id":_vm.$vuetify.breakpoint.smAndDown ||
                        _vm.$vuetify.breakpoint.lg
                            ? 'virtual-scroll-table'
                            : 'componente'}},[_c('WOGeneralInfo',{attrs:{"workOrder":_vm.order,"readOnlyWO":_vm.readOnlyWO},on:{"close":_vm.close}})],1)]),_c('v-tab-item',[_c('BOM',{attrs:{"workOrder":_vm.order}})],1)],1)],1),_c('Errors'),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.rejectionWOTable),callback:function ($$v) {_vm.rejectionWOTable=$$v},expression:"rejectionWOTable"}},[(_vm.rejectionWOTable)?_c('RejectionWOTable',{attrs:{"items":_vm.order.rejectionHistory},on:{"closeDialog":_vm.closeRejected}}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","retain-focus":false,"max-width":"400px"},model:{value:(_vm.loadFilesDialog),callback:function ($$v) {_vm.loadFilesDialog=$$v},expression:"loadFilesDialog"}},[_c('LoadFiles',{attrs:{"numberOfFiles":_vm.numberOfFiles,"accumulated":_vm.accumulated}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }