<template>
    <v-card :loading="loading">
        <v-card-title class="py-0">
            <v-btn
                small
                icon
                @click="close"
                color="primary"
                class="ml-n3 mr-2 my-2 py-0"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular my-2 py-0">
                {{
                    $vuetify.breakpoint.smAndDown ? 'WO' : 'Process work order'
                }}
                <span class="font-weight-black"
                    >{{ order.code }}
                    {{
                        $vuetify.breakpoint.smAndDown
                            ? ''
                            : `| ${order.project.reference} ${order.project.name}`
                    }}</span
                >
            </h2>
            <v-btn
                v-if="order.rejectionHistory"
                small
                icon
                @click="openRejected"
                color="red"
                class="ml-2 py-0"
            >
                <v-icon>
                    mdi-book-cancel-outline
                </v-icon>
            </v-btn>
            <v-spacer />
            <div class="d-flex py-0">
                <v-btn small icon color="primary" @click="downloadPDFs">
                    <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
                <v-btn small icon color="primary" @click="downloadXLS">
                    <v-icon>mdi-file-excel</v-icon>
                </v-btn>
                <v-btn small icon color="primary" @click="print">
                    <v-icon>mdi-printer-pos</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-divider class="ml-4 mr-5 py-0" />
        <v-card-text class="px-4 my-0 py-0">
            <v-tabs
                v-model="tab"
                ref="tabs"
                active-class="white"
                background-color="grey lighten-3"
                hide-slider
            >
                <v-tab
                    v-for="section in sections"
                    :key="section"
                    class="text-uppercase"
                >
                    {{ section }}
                </v-tab>
            </v-tabs>
            <v-tabs-items touchless v-model="tab">
                <v-tab-item>
                    <div
                        :id="
                            $vuetify.breakpoint.smAndDown ||
                            $vuetify.breakpoint.lg
                                ? 'virtual-scroll-table'
                                : 'componente'
                        "
                        :style="
                            `height: ${
                                $vuetify.breakpoint.xs
                                    ? $vuetify.breakpoint.height - 110
                                    : $vuetify.breakpoint.sm
                                    ? $vuetify.breakpoint.height - 250
                                    : $vuetify.breakpoint.height - 124
                            }px`
                        "
                    >
                        <WOGeneralInfo
                            :workOrder="order"
                            :readOnlyWO="readOnlyWO"
                            @close="close"
                        />
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <BOM :workOrder="order" />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <!--Error Alert-->
        <Errors />
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionWOTable">
            <RejectionWOTable
                v-if="rejectionWOTable"
                :items="order.rejectionHistory"
                @closeDialog="closeRejected"
            />
        </v-dialog>
        <!--LOAD FILES-->
        <v-dialog
            v-model="loadFilesDialog"
            persistent
            :retain-focus="false"
            max-width="400px"
        >
            <LoadFiles
                :numberOfFiles="numberOfFiles"
                :accumulated="accumulated"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import API from '@/services/api'
import _ from 'lodash'
import JSZip from 'jszip'
import { storage } from '@/services/firebase'
import moment from 'moment'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

export default {
    name: 'ProcessWorkOrder',
    props: {
        order: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        readOnlyWO: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        WOGeneralInfo: () =>
            import('@/components/WorkOrders/WOGeneralInfo.vue'),
        Errors: () => import('@/components/Layout/Errors'),
        BOM: () => import('@/components/WorkOrders/BOM.vue'),
        RejectionWOTable: () =>
            import('@/components/WorkOrders/RejectionWOTable.vue'),
        LoadFiles: () => import('@/components/WorkOrders/LoadFiles.vue'),
    },
    data: () => ({
        loading: false,
        sections: ['general information', 'bom'],
        tab: null,
        rejectionWOTable: false,
        downloadedFiles: [],
        partNumbers: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        projectManager: {},
        creator: {},
        company: {},
        numberOfFiles: 0,
        accumulated: 0,
        loadFilesDialog: false,
    }),
    async mounted() {
        try {
            if (typeof this.order.project.projectManager == 'string') {
                this.projectManager = await API.getUser(
                    this.order.project.projectManager
                )
            } else {
                this.projectManager = this.order.project.projectManager
            }
            if (this.readOnlyWO) {
                this.sections = [this.sections[0]]
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async createZip() {
            const zip = new JSZip()

            for (const file of this.downloadedFiles) {
                zip.file(file.name, file.data)
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' })
            return zipBlob
        },
        getPaths() {
            let paths = []
            if (this.order.items) {
                this.order.items.forEach(item => {
                    if (item.files) {
                        paths = [...paths, ...item.files]
                    }
                })
            }
            this.numberOfFiles = paths.length
            return paths
        },
        async downloadAllFiles() {
            for (const filePath of this.getPaths()) {
                await this.downloadFile(filePath)
                this.accumulated += 1
            }
        },
        async downloadFile(filePath) {
            try {
                const fileRef = storage().ref(
                    `${this.companyId}/workOrders/${this.order.id}/items/${filePath}`
                )
                let url
                await fileRef
                    .getDownloadURL()
                    .then(response => {
                        url = response
                    })
                    .catch(() => {
                        url = ''
                    })
                if (url) {
                    const response = await fetch(url)
                    const blob = await response.blob()
                    this.downloadedFiles.push({ name: filePath, data: blob })
                }
            } catch (error) {
                console.error(`Error al descargar ${filePath}:`, error)
            }
        },
        async downloadPDFs() {
            try {
                this.loading = true
                this.loadFilesDialog = true
                await this.downloadAllFiles()
                const zipBlob = await this.createZip()
                const zipUrl = URL.createObjectURL(zipBlob)
                const link = document.createElement('a')
                link.href = zipUrl
                link.download = `${this.order.code}.zip`
                link.click()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.numberOfFiles = 0
                this.accumulated = 0
                this.loadFilesDialog = false
            }
        },
        async print() {
            try {
                this.loading = true
                const items = await this.completeData()
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: [233, 279],
                })
                // set header and footer
                var company = this.company
                var order = this.order
                const aspectRatio = await this.getAspectRatio(
                    company.logoBase64
                )
                const header = function(data) {
                    pdf.setFontSize(16)
                    pdf.setFont('helvetica', 'bold')
                    pdf.text(
                        `${order.code} | ${order.project.reference} ${order.project.name}`,
                        data.settings.margin.left,
                        15
                    )
                    pdf.text(
                        `${moment
                            .unix(
                                order.createdOn.seconds ||
                                    order.createdOn._seconds
                            )
                            .format('YYYY-MM-DD')}`,
                        data.settings.margin.left,
                        23
                    )
                    // add company logo
                    const imgHeight = 20
                    const imgWidth = imgHeight * aspectRatio
                    const imgX =
                        pdf.internal.pageSize.width -
                        data.settings.margin.right -
                        imgWidth // Position X
                    const imgY = 10 // Position Y
                    pdf.addImage(
                        company.logoBase64,
                        'PNG',
                        imgX,
                        imgY,
                        imgWidth,
                        imgHeight
                    )
                }

                const footer = function(data) {
                    pdf.setLineWidth(0.5) // Set line thickness
                    pdf.line(
                        10,
                        pdf.internal.pageSize.height - 10,
                        pdf.internal.pageSize.width - 10,
                        pdf.internal.pageSize.height - 10
                    )

                    pdf.setFontSize(12)
                    pdf.text(
                        `${data.pageNumber}`,
                        pdf.internal.pageSize.width - 13,
                        pdf.internal.pageSize.height - 5
                    )
                }
                const fontSize = 12
                pdf.setFontSize(fontSize)
                // description text
                const descriptionText = [
                    `PRODUCTION END: ${
                        this.order.productionEnd
                            ? this.formatDate(
                                  this.order.productionEnd.seconds ||
                                      this.order.productionEnd._seconds
                              )
                            : ''
                    }`,
                    `CREATED BY: ${this.order.createdBy.name}`,
                    `PROJECT: ${this.order.project.name}`,
                    `PROJECT MANAGER: ${this.projectManager.name}`,
                    `NOTES: ${this.order.notes || ''}`,
                ]
                // Calculate the total height of the description text
                const lineHeight = fontSize * 0.5 // Line spacing
                const descriptionHeight = descriptionText.length * lineHeight
                descriptionText.forEach((line, index) => {
                    pdf.text(line, 10, 35 + (index + 1) * lineHeight)
                })
                // autotable options
                const options = {
                    margin: { top: 40, left: 10, right: 10 },
                    startY: descriptionHeight + 45,
                }
                // calculate total mass
                const totalMass = this.order.items
                    ? this.order.items
                          .reduce(
                              (accumulator, item) =>
                                  accumulator + item.mass * item.quantity,
                              0
                          )
                          .toFixed(1)
                    : 0
                // total painting area
                const totalPaintingArea = this.order.items
                    ? this.order.items
                          .reduce(
                              (accumulator, item) =>
                                  accumulator +
                                  item.paintingArea * item.quantity,
                              0
                          )
                          .toFixed(1)
                    : 0
                // total quantity
                const totalQuantity = this.order.items
                    ? this.order.items.reduce(
                          (accumulator, item) => accumulator + item.quantity,
                          0
                      )
                    : 0
                // add table
                pdf.autoTable({
                    head: [
                        [
                            'ID',
                            'PART NUMBER',
                            'DESCRIPTION',
                            'MASS',
                            'PAINTING AREA',
                            'FINISH & UC CODE',
                            'QTY',
                            'NOTES',
                            'PROCESSES',
                        ],
                    ],
                    body: [
                        ...items.map((item, index) => [
                            index + 1,
                            item.partNumber,
                            item.description,
                            item.mass,
                            item.paintingArea,
                            item.dataFinishAndUCCode
                                ? item.dataFinishAndUCCode.name
                                : '',
                            item.quantity,
                            item.notes,
                            item.dataProcesses
                                .map(process => process.name)
                                .join(', '),
                        ]),
                        [
                            '',
                            'Total',
                            '',
                            `${totalMass} (kgs)`,
                            `${totalPaintingArea} (m2)`,
                            '',
                            totalQuantity,
                            '',
                            '',
                        ],
                    ],
                    ...options,
                    didDrawPage: function(data) {
                        header(data)
                        footer(data)
                    },
                    columnStyles: {
                        0: { cellWidth: 8 },
                        1: { cellWidth: 25 },
                        2: { cellWidth: 30 },
                        3: { cellWidth: 25 },
                        4: { cellWidth: 25 },
                        5: { cellWidth: 25 },
                        6: { cellWidth: 15 },
                        7: { cellWidth: 30 },
                        8: { cellWidth: 30 },
                        9: { cellWidth: 30 },
                    },
                })
                const pdfDataUri = pdf.output('datauristring')
                const blob = this.dataURItoBlob(pdfDataUri)
                const blobURL = URL.createObjectURL(blob)
                const printWindow = window.open(blobURL)
                printWindow.onload = function() {
                    printWindow.print()
                    URL.revokeObjectURL(blobURL)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        dataURItoBlob(dataURI) {
            const byteString = Uint8Array.from(atob(dataURI.split(',')[1]), c =>
                c.charCodeAt(0)
            )
            const blobType = dataURI
                .split(',')[0]
                .split(':')[1]
                .split(';')[0]
            return new Blob([byteString], { type: blobType })
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        async completeData() {
            try {
                const {
                    data: { settings },
                } = await API.getSettings()
                this.company = settings.find(
                    s => s.name == 'Company'
                ).generalInfo
                const settingProcesses = settings.find(
                    s => s.name == 'Processes'
                )
                const settingFinish = settings.find(
                    s => s.name == 'FinishAndUCCode'
                )
                const partNumbers = await API.getPartNumbers(
                    this.order.projectId
                )
                const items = _.clone(this.order.items)
                if (items && items.length > 0) {
                    for (let item of items) {
                        item.workOrder = this.order.code
                        const partNumber = partNumbers.find(
                            pn => pn.id === item.partNumberId
                        )
                        if (partNumber) {
                            item.partNumber = partNumber.code
                        }
                        if (settingProcesses.id) {
                            if (item.processes?.length > 0) {
                                item.dataProcesses = settingProcesses.processes.filter(
                                    process =>
                                        item.processes.includes(process.id)
                                )
                            }
                        }
                        if (settingFinish.id) {
                            if (item.finishAndUCCode) {
                                item.dataFinishAndUCCode = settingFinish.finishes.find(
                                    e => e.id == item.finishAndUCCode
                                )
                            }
                        }
                    }
                }
                return items
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async downloadXLS() {
            try {
                const items = await this.completeData()
                const wsData = items.map(item => ({
                    'Work Order': item.workOrder,
                    'Part Number': item.partNumber,
                    'Production Start': this.order.productionStart
                        ? this.formatDate(
                              this.order.productionStart.seconds ||
                                  this.order.productionStart._seconds
                          )
                        : '',
                    'Production End': this.order.productionEnd
                        ? this.formatDate(
                              this.order.productionEnd.seconds ||
                                  this.order.productionEnd._seconds
                          )
                        : '',
                    Project: this.order.project.name,
                    Description: item.description,
                    Mass: item.mass,
                    'Painting Area': item.paintingArea,
                    'Finish & UC Code': item.dataFinishAndUCCode
                        ? item.dataFinishAndUCCode.name
                        : '',
                    QTY: item.quantity,
                    notes: item.notes,
                    processes: item.dataProcesses
                        .map(process => process.name)
                        .join(', '),
                }))
                const ws = XLSX.utils.json_to_sheet(wsData)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
                const wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                })
                const blob = new Blob([wbout], {
                    type: 'application/octet-stream',
                })
                const url = window.URL.createObjectURL(blob)
                const tempLink = document.createElement('a')
                tempLink.href = url
                tempLink.setAttribute('download', `${this.order.code}.xlsx`)
                tempLink.click()
                window.URL.revokeObjectURL(url)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeRejected() {
            this.rejectionWOTable = false
        },
        openRejected() {
            this.rejectionWOTable = true
        },
        close() {
            this.$emit('close')
        },
        async getAspectRatio(file) {
            return new Promise(function(resolved) {
                var i = new Image()
                i.onload = function() {
                    resolved(i.width / i.height)
                }
                i.src = file
            })
        },
    },
}
</script>

<style></style>
